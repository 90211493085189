.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;

  pointer-events: none;
}

.ultra-cls {
  position: absolute;
  width: 70vw;
  right: 0;
  height: 100vh;
  object-fit: cover;
  background-repeat: no-repeat;
  z-index: 1;
  border-left: #ff8800 10px solid;
}

.actions {
  position: fixed;
  top: 50px;
  right: 50px;
  z-index: 2;
}

h1 {
  font-size: 1.8em;
  margin: 0;
}

h2 {
  max-width: 400px;
  font-size: 1em;
  margin: 0 auto;
}
p {
  font-size: 0.7em;
  max-width: 500px;
}

.container-cbn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: #48acb1;
  padding-bottom: 30px;
  border-radius: 20px;
  margin-left: calc(8%);
}

.actions-btn {
  font-size: 1em;
  background-color: #ff8800;
  border-radius: 18px;
  padding: 6px 15px 8px 15px;
  color: white;
  cursor: pointer;
}

.sebas {
  max-height: 200px;
  margin-top: -20vh;
  margin-left: -0;
}

.align-header {
  max-width: 300px;
  margin-top: 0vh;
  margin-bottom: 0;
  text-align: start;
  padding: 10px;
}

.underline {
  text-decoration: underline;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  display: inline-block;
  animation: bounce 5s infinite;
}

.title {
  margin-top: 1vh;
  display: inline-block;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: bounce 10s linear;
  }
}

.App-header {
  background-color: #48abb1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 0vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 900px) {
  .container-cbn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background-color: #48acb1;
    padding-bottom: 20px;
    border-radius: 20px;
    margin-left: 0;
  }
  .actions {
    position: fixed;
    top: 30px;
    right: 30px;
  }
  .ultra-cls {
    position: absolute;
    width: 100vw;
    bottom: 0;
    left: 0;
    height: 54vh;
    object-fit: cover;
    background-repeat: no-repeat;
    z-index: 1;
    border-top: #ff8800 10px solid;
    border-left: none;
  }

  .sebas {
    max-height: 200px;
    margin-left: 0vw;
  }
  .App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    margin-top: -10vh;
  }
  h2 {
    max-width: 200px;
    font-size: 1em;
  }
  p {
    font-size: 0.7em;
    max-width: 300px;
    padding: 0 20px;
  }
}
